<template>
    <div class="integral">
        <div class="title">我的积分</div>
        <div class="moone">
            <div class="itwrap">
                <div class="items">
                    <div>当前总积分</div>
                    <div class="top">{{aggregateScore}}</div>
                </div>
                <div class="items">
                    <div>可用积分</div>
                    <div class="top">{{availableIntegral}}</div>
                </div>
                <div class="items">
                    <div>冻结积分</div>
                    <div class="top">{{freezingPoints}}</div>
                </div>
                <div class="items">
                    <div class="gobtn">
                        <!-- <el-button type="success" @click="toShop()">去兑换礼品</el-button> -->
                        <a  target="_blank" @click="toShop()">去兑换礼品</a>
                    </div>
                </div>
            </div>
            <div class="pipe">
                <img src="../../../assets/image/tishi.png" alt="" srcset="">
                您在孔雀有礼上在线消费多少金额，并确认成交后将同步赠送等值积分到您的积分账户中。
                <router-link to="#" class="more">更多积分方式获取>></router-link>
            </div>
        </div>
        <!-- tab -->
        <el-tabs v-model="activeName" type="card">
            <el-tab-pane label="全部明细" @tab-click="getDatas(0)" name="first">
                <el-form ref="form" :model="form" label-width="80px">
                    <!-- <el-form-item label="时间类型">
                         <el-select v-model="form.region" placeholder="选择时间">
                             <el-option label="最近三个月" value="shanghai"></el-option>
                             <el-option label="最近六个月" value="beijing"></el-option>
                             <el-option label="最近一年" value="shanghai"></el-option>
                         </el-select>
                     </el-form-item>-->
                </el-form>
                <ul class="itemwrap">
                    <li>日期</li>
                    <li>收入/支出</li>
                    <li>积分</li>
                    <li>对应订单号</li>
                    <li>说明</li>
                </ul>
                <template v-for="item in list">
                    <ul class="itemwrap item">
                        <li>{{item.createDate}}</li>
                        <li>{{checkState(item.sign)}}</li>
                        <li>{{item.number}}</li>
                        <li></li>
                        <li>{{item.text}}</li>
                    </ul>
                </template>
                <div class="pagination">
                    <div class="block">
                        <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="currentPage4"
                                :page-sizes="[5, 10, 15, 20]"
                                :page-size="8"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="totalSize"
                        >
                        </el-pagination>
                    </div>
                </div>
            </el-tab-pane><!--
            <el-tab-pane label="收入明细" @tab-click="getDatas(1)" name="second">收入明细</el-tab-pane>
            <el-tab-pane label="支出明细" @tab-click="getDatas(-1)" name="third">支出明细</el-tab-pane>-->
        </el-tabs>

    </div>
</template>

<script>
    import * as api from '@/api/api'

    export default {
        name: 'integral',
        data() {
            return {
                list: [],//列表数据
                totalSize: 0, //一共几条数据
                currentPage4: 1, //前往第几页默认展示去往第一页，在结构中需绑定
                page: 1, //刷新页面，展示第一页，每页5行
                rows: 5,
                mobile: this.$store.getters.user.mobile,
                activeName: 'first',
                jftype: 0,//收入(1)、支出（-1)类型、0是ALL
                availableIntegral: 0,//可用积分
                aggregateScore: 0,//总积分
                freezingPoints: 0,//冻结积分  https://www.kqwad.com/pointsmall/index.html
                url: "",
                form: {
                    region: "",
                }
            }
        },
        components: {},
        methods: {
            toShop() {
                window.open(''+this.url+'');
            },
            // incomeType(type){
            //     return type === 1 ? "+" : "-";
            // },
            checkState(type) {
                return type === 1 ? "收入" : "支出";
            },
            genJFShopLink() {
                var _that = this;
                var param = {phone: _that.mobile};
                api.genJFShopLink(param).then(res => {
                    var code = res.data.code;
                    if (code === 200 || code === 0) {
                        _that.url = res.data.data;
                    }
                });
            },
            getDatas(type) {
                var _that = this;
                if (type !== 10) {
                    _that.jftype = type;
                }
                var param = {phone: _that.mobile, page: _that.page, rows: _that.rows, jftype: _that.jftype};
                api.getPersonJFDetail(param).then(res => {
                    var code = res.data.code;
                    if (code === 200 || code === 0) {
                        _that.list = res.data.data.data;
                        _that.totalSize = res.data.data.totalSize;
                        _that.availableIntegral = res.data.data.availajf;
                        _that.aggregateScore = res.data.data.alljf;
                        _that.freezingPoints = res.data.data.djjf;
                        console.log(res.data.data.data)
                    }
                });
            },
            handleClick(tab, event) {
                console.log(tab, event);
            },
            handleSizeChange(val) {//分页
                console.log(`每页 ${val} 条`);
                var _that = this;
                _that.rows = `${val}` //每页多少条
                _that.getDatas(10);
            },
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
                var _that = this;
                _that.page = `${val}`; //一共几页
                _that.getDatas(10);
            },
        },
        created() {
            var _that = this;
            _that.getDatas(0);//获取页面数据
            _that.genJFShopLink();//生成积分商城的跳转链接
        }
    }
</script>

<style lang='scss'>
    @import "../../../assets/scss/common.scss";
    @import "../../../assets/scss/reset.scss";

    $theme-color: #d82229;
    li {
        list-style: none;
    }

    .integral {
        padding: 30px 20px;

        .title {
            font-size: 18px;
        }
        // .el-button--success{
        //     border: 1px solid #d82229;
        // }
        .moone {
            margin: 20px 0;
            background-color: #f5f5f5;

            .itwrap {
                display: flex;
                font-size: 16px;
                color: #333333;

                .items {
                    width: 110px;
                    height: 80px;
                    margin: 20px 40px 0;

                    .top {
                        margin-top: 15px;
                        font-size: 30px;
                        color: $theme-color;
                    }

                    .gobtn {
                        background-color: $theme-color;
                        margin-left: 180px;
                        a{
                            width: 110px;
                            height: 40px;
                            display: block;
                            color: #fff;
                            text-align: center;
                            line-height: 40px;
                            background: #d82229;
                            border-radius: 4px;
                            margin-top: 18px;
                            text-decoration: none;
                            cursor: pointer;
                        }
                        // .el-button--success {
                        //     background-color: $theme-color;
                        //     margin-top: 18px;
                        // }
                    }
                }
            }

            .pipe {
                font-size: 14px;
                color: #666666;
                padding: 0 10px 20px 35px;

                img {
                    vertical-align: text-bottom;
                    margin-right: 10px;
                }

                .more {
                    color: $theme-color;
                    text-decoration: none;
                }

            }
        }

        .el-form {
            width: 250px;
            float: right;

            .el-form-item {
                margin-bottom: 10px;
            }
        }

        .itemwrap {
            clear: both;
            overflow: hidden;
            padding: 0;
            margin: 0;
            background: #f5f5f5;
            border: 1px solid #e5e5e5;
            font-size: 15px;
            color: #666666;

            > li {
                float: left;
                width: 20%;
                text-align: center;
                height: 50px;
                line-height: 50px;
            }
        }

        .item {
            background-color: #ffffff;
            border: 1px solid #eaeaea;
            border-top: none;

            > li {
                height: 60px;
                line-height: 60px;
            }
        }
        #tab-first{
            color: #d82229;
        }

        .pagination {
            width: 537px;
            height: 40px;
            margin: 60px auto 0;
        }
    }
</style>
