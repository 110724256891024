<template>
    <div id="personOrder">
        <PersonOrderHeader></PersonOrderHeader>
        <!-- 最近订单 -->
        <div class="grayBg"></div>
        <div class="orderContent">
            <div class="orderName row">
                <div class="flex align-center">
                    <span></span>
                     最近订单
                </div>
            </div>
            <div class="noOrderContent">
                <img src="../../../../assets/shopImg/personOrderImg/wudingdanlogo.png" alt="">
                <div class="">尚无您的记录，去<label @click="goHomePage">首页</label>看看吧~</div>
                <div class="">您需要登录才能查看最近的订单哦~</div>
            </div>
        </div>
    </div>
</template>
<script>
import PersonOrderHeader from "@/components/common/personOrderHeader";
export default {
    components: { PersonOrderHeader },
    data(){
        return {

        }
    },
    methods:{
        goHomePage(){
            this.$router.push({
                path:'/'
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.grayBg {
    height: 22px;
    background-color: #f5f5f5;
}

.orderContent {
    padding: 20px;
    background-color: #fff;
    .orderName {
        padding-left: 12px;
        span {
            display: inline-block;
            width: 4px;
            height: 20px;
            margin-right: 10px;
            background-color: #0db168;
        }  
    }
    .noOrderContent {

        margin: 70px auto 0;
        img {
            position: relative;
            top: 0;
            left: 0;
            z-index: 90;
            display: block;
            width: 442px;
            height: 260px;
            margin: 0 auto;
        }

        div {
            position: relative;
            top: -80px;
            left: 0;
            z-index: 99;
            color: #666;
            font-size: 18px;
            text-align: center;
            label {
                color: #0db168;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}

</style>