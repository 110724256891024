<template>
  <div class="product_wrap">
    <div class="line">
      <!-- <div class="nav_info flex">
                您的位置：首页>商务印刷>制品系列>彩页/折页/封套>A3彩印合版 超市彩页餐饮传单
      </div>-->
    </div>
    <div style="background:#f5f5f5;">
      <section>
        <div class="left_sec">
          <div class="title">热卖推荐</div>
          <div class="main_item" v-for="item in data" :key="item" @click="detail">
            <div class="item">
              <img src="../../assets/image/remaituijian.jpg" alt>
              <div class="name">展架</div>
              <div class="price">￥220.00</div>
            </div>
          </div>
        </div>
        <div class="right_sec">
          <div class="top_fliter">
            <div class="fliter_conditions">
              <span class="up">销量</span>
            </div>
            <div class="fliter_conditions down">
              <span class="down">销量</span>
            </div>
          </div>
          <div class="productList">
            <div class="product" v-for="item in 12" :key="item" @click="detail">
              <img src="../../assets/image/remaituijian.jpg" alt>
              <div class="productName">
                <span>广告</span>
                <span style="color:#0db168;">纸杯</span>
              </div>
              <div class="productPrice">￥220.00</div>
            </div>
          </div>
          <div class="pagination">
            <div>上一页</div>
            <div class="border active">1</div>
            <div class="border">2</div>
            <div class="border">下一页</div>
            <div>共2页,</div>到第
            <input class="inputNum" type="number" value="1">
            页
            <div class="border">确定</div>
          </div>
        </div>
      </section>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
export default {
  name: 'index',
  components: {  },
  data() {
    return {
      data: [2, 15, 164, 12]
    };
  },
  methods: {
    detail() {
      this.$router.push({ path: '/price' });
    }
  }
};
</script>

<style lang="scss" scoped>
.product_wrap {
  height: 100%;
  width: 100%;
  section {
    width: 1200px;
    margin: 0 auto 0;
    overflow: hidden;
    clear: both;
    .left_sec {
      width: 224px;
      overflow-x: auto;
      margin-right: 20px;
      float: left;
      background: #fff;
      .title {
        line-height: 40px;
        height: 40px;
        text-align: center;
        color: #fff;
        font-size: 15px;
        background: #0db168;
      }
      .main_item {
        width: 100%;
        .item {
          width: 184px;
          background: #fff;
          margin: 20px auto 0;
          img {
            width: 184px;
            height: 184px;
          }
          div {
            text-align: left;
            &.name {
              font-size: 16px;
              color: #333333;
            }
            &.price {
              font-size: 18px;
              color: #ff0036;
            }
          }
        }
      }
    }
    .right_sec {
      width: 956px;
      float: left;
      background: #f5f5f5;
      .top_fliter {
        height: 40px;
        background: #eaeaea;
        text-align: left;
        margin-bottom: 20px;
        .fliter_conditions {
          width: 100px;
          text-align: center;
          line-height: 40px;
          font-size: 15px;
          display: inline-block;
          span {
            display: inline-block;
            height: 100%;
            width: 100%;
            &.up {
              background: url(../../assets/image/shangshen.png) center no-repeat;
              background-size: 14px;
              background-position-x: 70px;
              background-position-y: 10px;
              color: #0db168;
            }
            &.down {
              background: url(../../assets/image/xiajiang.png) center no-repeat;
              background-size: 14px;
              background-position-x: 70px;
              background-position-y: 14px;
            }
          }
        }
      }
      .productList {
        margin: 0 -10px;
        overflow: hidden;
        .product {
          width: 224px;
          height: 300px;
          margin: 0 10px 20px;
          float: left;
          background: #fff;
          > img {
            width: 224px;
            height: 224px;
          }
          div {
            padding-left: 15px;
            text-align: left !important;
            font-size: 16px;
          }
          .productPrice {
            color: #ff0036;
            font-size: 18px;
            margin-top: 10px;
          }
          .productName {
          }
        }
      }
      .pagination {
        clear: both;
        margin: 40px 0;
        text-align: center;
        .inputNum {
          background: transparent;
          width: 34px;
          height: 34px;
          border: none;
          outline: none;
          text-align: center;
          border: 1px solid #e5e5e5;
        }
        div {
          display: inline-block;
          height: 34px;
          line-height: 34px;
          text-align: center;
          color: #333333;
          font-size: 14px;
          margin: 0 5px;
          padding: 0 14px;
          box-sizing: border-box;
          &.active {
            border: 1px solid #0db168 !important;
          }
          &.border {
            border: 1px solid #e5e5e5;
            &:hover {
              border: 1px solid #0db168 !important;
            }
          }
        }
      }
    }
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}
.left_sec::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.left_sec {
  -ms-scroll-chaining: chained;
  -ms-overflow-style: none;
  -ms-content-zooming: zoom;
  -ms-scroll-rails: none;
  -ms-content-zoom-limit-min: 100%;
  -ms-content-zoom-limit-max: 500%;
  -ms-scroll-snap-type: proximity;
  -ms-scroll-snap-points-x: snapList(100%, 200%, 300%, 400%, 500%);
  -ms-overflow-style: none;
  overflow: auto;
}
.left_sec .-o-scrollbar {
  -moz-appearance: none !important;
  background: rgba(0, 255, 0, 0) !important;
}
</style>