<template>
    <div id="personOrder">
        <PersonOrderHeader></PersonOrderHeader>
        <!-- 最近订单 -->
        <div class="grayBg"></div>
        <div class="orderContent">
            <div class="orderName row">
                <div class="flex align-center">
                    <span></span>
                    最近订单
                </div>
            </div>
            <div class="orderNav">
                <span>产品/需求信息</span>
                <span>单价</span>
                <span>数量</span>
                <span>订单金额</span>
                <span>
          订单状态
          <label for class="arrow" style="margin-left:4px"></label>
        </span>
                <span>交易操作</span>
            </div>
            <template v-for="(item, index) in mes.data">
                <div class="orderBox" :key="index">
                    <div class="orderHeader">
                        <span>{{item.createDate}}</span>
                        <span>订单号：{{item.orderSn}}</span>
                        <span>{{item.storeShortName}}</span>
                        <!-- <span>订单总金额：{{item.totalAmount}}</span> -->
                    </div>
                    <template v-for="(items,i) in item.orderItemList">
                        <div class="listContent row" :key="i">
                            <div class="imgWrap">
                                <template v-if="item.orderType === 3">
                                    <img src="https://imag.kqwad.com/upload/4b189f6ac9de45a6b0637140056fccd6.png"
                                         alt=""/>
                                </template>
                                <template v-else>
                                    <img :src="items.logoUrl | imagePath" alt>
                                </template>
                            </div>

                            <div class="desc">
                                <p>{{items.productName}}</p>
                                <template v-for="k,v in items.attrList">
                                    <template v-for="v1,v2 in k">
                                        <p>{{v2}}：{{v1}}</p>
                                    </template>
                                </template>
                            </div>
                            <div class>￥{{items.price}}</div>
                            <div class>{{items.productNum}}</div>
                            <div class="price">￥{{items.price*items.productNum}}</div>
                            <div class="status flex-y align-center">
                                <span>{{state[item.state]}}</span>
                                <router-link :to="'/orderDetail/'+items.orderSn" tag="div">
                                    <span>订单详情</span>
                                </router-link>
                            </div>
                            <div class="payStatus flex-y align-center">
                                <template v-if="item.state === 0">
                                    <button @click="pay(item)">立即支付</button>
                                    <span class="cancelOrder" @click="cancelOrder(i,items.orderSn)">取消订单</span>
                                </template>
                                <template v-if="item.state === 2 ">
                                    <button @click="confirmReceipt(items.orderSn)">确认收货</button>
                                </template>
                            </div>
                        </div>
                    </template>
                </div>
            </template>
            <!--<div class="orderBox">
                      <div class="orderHeader">
                          <span>2019-04-22 23:58:09</span>
                          <span>订单号：kd566678777</span>
                          <span>南京华航商务</span>
                      </div>
                      <div class="listContent row">
                          <img src="../../../assets/shopImg/personOrderImg/chanpintu.png" alt="">
                          <div class="desc">
                              <p>此处为产品名称</p>
                              <p>属性：属性值</p>
                              <p>属性：属性值</p>
                              <p>属性：属性值</p>
                          </div>
                          <div class="">￥35</div>
                          <div class="">1</div>
                          <div class="price">￥200</div>
                          <div class="status flex-y align-center">
                              <span>关闭交易</span>
                              <span>订单详情</span>
                          </div>
                          <div class="payStatus flex-y align-center">
                              <button>重新购买</button>
                              &lt;!&ndash; <span>取消订单</span> &ndash;&gt;
                          </div>
                      </div>
                  </div>
                  <div class="orderBox">
                      <div class="orderHeader">
                          <span>2019-04-22 23:58:09</span>
                          <span>订单号：kd566678777</span>
                          <span>南京华航商务</span>
                      </div>
                      <div class="listContent row">
                          <img src="../../../assets/shopImg/personOrderImg/chanpintu.png" alt="">
                          <div class="desc">
                              <p>此处为产品名称</p>
                              <p>属性：属性值</p>
                              <p>属性：属性值</p>
                              <p>属性：属性值</p>
                          </div>
                          <div class="">￥35</div>
                          <div class="">1</div>
                          <div class="price">￥200</div>
                          <div class="status flex-y align-center">
                              <span>制作中</span>
                              &lt;!&ndash; <span>订单详情</span> &ndash;&gt;
                          </div>
                          <div class="payStatus flex-y align-center">
                              <button class="apply">上传素材</button>
                              &lt;!&ndash; <span>取消订单</span> &ndash;&gt;
                          </div>
                      </div>
                  </div>
                  <div class="orderBox">
                      <div class="orderHeader">
                          <span>2019-04-22 23:58:09</span>
                          <span>订单号：kd566678777</span>
                          <span>南京华航商务</span>
                      </div>
                      <div class="listContent row">
                          <img src="../../../assets/shopImg/personOrderImg/chanpintu.png" alt="">
                          <div class="desc">
                              <p>此处为产品名称</p>
                              <p>属性：属性值</p>
                              <p>属性：属性值</p>
                              <p>属性：属性值</p>
                          </div>
                          <div class="">￥35</div>
                          <div class="">1</div>
                          <div class="price">￥200</div>
                          <div class="status flex-y align-center">
                              <span>制作中</span>
                              <span>订单详情</span>
                          </div>
                          <div class="payStatus flex-y align-center">
                              <button class="orange">撤销申请</button>
                              &lt;!&ndash; <span>取消订单</span> &ndash;&gt;
                          </div>
                      </div>
                  </div>
                   <div class="orderBox">
                      <div class="orderHeader">
                          <span>2019-04-22 23:58:09</span>
                          <span>订单号：kd566678777</span>
                          <span>南京华航商务</span>
                      </div>
                      <div class="listContent row">
                          <img src="../../../assets/shopImg/personOrderImg/chanpintu.png" alt="">
                          <div class="desc">
                              <p>此处为产品名称</p>
                              <p>属性：属性值</p>
                              <p>属性：属性值</p>
                              <p>属性：属性值</p>
                          </div>
                          <div class="">￥35</div>
                          <div class="">1</div>
                          <div class="price">￥200</div>
                          <div class="status flex-y align-center">
                              <span>待收货</span>
                              <span>订单详情</span>
                          </div>
                          <div class="payStatus flex-y align-center">
                              <button>确认收货</button>
                              &lt;!&ndash; <span>取消订单</span> &ndash;&gt;
                          </div>
                      </div>
            </div>-->
        </div>
    </div>
</template>
<script>
    import PersonOrderHeader from '@/components/common/personOrderHeader'
    import * as api from '@/api/api'

    export default {
        components: {PersonOrderHeader},
        mounted() {
            this.getHtmlData()
        },
        data() {
            return {
                orderSn: '',
                mes: {}, //页面对象
                state: ['待付款', '制作中', '待收货', '交易完成', '交易关闭', '退款中']
            }
        },
        methods: {
            cancelOrder(i, orderSn) {
                //此为取消订单
                this.orderSn = orderSn
                this.$confirm('确定要取消订单吗', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                })
                    .then(() => {
                        let self = this
                        // console.log(this.orderSn)
                        let Params = {orderSn: this.orderSn, status: 5}
                        api.cancelOrder(Params).then(res => {
                            this.getHtmlData()
                        })
                        this.$message({
                            type: 'success',
                            message: '取消订单成功!'
                        })
                    })
                    .catch(() => {
                        //未取消订单
                    })
            },
            //确认收货
            async confirmReceipt(orderSn) {
                await this.$http.post('/storeMoney/confirmReceipt?orderSn=' + orderSn).then(res => {
                    const code = res.data.code;
                    if (code === 0 || code === 200) {
                        this.$message({
                            type: 'success',
                            message: '确认收货成功!'
                        });
                        window.location.reload();
                    } else {
                        this.$message({
                            type: 'failed',
                            message: '操作失败!'
                        })
                    }
                });
            },
            async getHtmlData() {
                await this.$http.get('/tOrder/findGroupByState').then(res => {
                    let self = this;
                    self.mes = res.data.data;
                    // console.log(self.mes, 173)
                })
            },
            pay(item) {
                const {orderSn} = item
                this.$router.push({name: 'paystatus', params: {orderSn}})
            }
        }
    }
</script>

<style lang="scss" scoped>
    .orange {
        background: #ff9800 !important;
    }

    .apply {
        background: #fff !important;
        border: 1px solid #d82229 !important;
        color: #d82229 !important;
    }

    .grayBg {
        height: 22px;
        background-color: #f5f5f5;
    }

    /deep/ .orderContent {
        padding: 20px;
        background-color: #fff;

        .orderName {
            padding-left: 12px;

            span {
                display: inline-block;
                width: 4px;
                height: 20px;
                margin-right: 10px;
                background-color: #d82229;
            }
        }

        .orderNav {
            height: 40px;
            background: #f5f5f5;
            margin: 20px 0;
            line-height: 40px;
            color: #666;
            font-size: 15px;
            display: flex;
            justify-content: space-between;
            border: 1px solid #e5e5e5;
            padding: 0 20px;

            span {
                &:first-child {
                    width: 25%;
                    text-align: center;
                }

                &:nth-child(2) {
                    width: 10%;
                    text-align: center;
                    margin-left: 33px;
                }

                &:nth-child(3) {
                    width: 10%;
                    text-align: center;
                    margin-left: -10px;
                }

                &:nth-child(4) {
                    width: 10%;
                    text-align: center;
                    margin-left: -5px;
                }

                &:nth-child(5) {
                    width: 7%;
                    text-align: center;
                }

                &:nth-child(6) {
                    width: 10%;
                    text-align: center;
                }
            }
        }

        .orderBox {
            margin: 20px 0 18px;
            border-left: 1px solid #ddd;
            border-right: 1px solid #ddd;
            border-bottom: 1px solid #ddd;

            .orderHeader {
                line-height: 35px;
                padding: 6px 0 0 15px;
                background-color: #f5f5f5;
                border: 1px solid #e5e5e5;
                border-left: none;
                border-right: none;
                font-size: 15px;
                color: #666;

                span {
                    &:nth-child(2) {
                        padding: 0 30px;
                    }
                }

                span {
                    &:nth-child(4) {
                        padding: 0 60px;
                        text-align: right;
                    }
                }
            }

            .listContent {
                padding: 20px;

                > div {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                > :nth-child(1) {
                    width: 10%;
                }

                > :nth-child(2) {
                    width: 15%;

                    > p {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                > :nth-child(3) {
                    display: block;
                    line-height: 80px;
                    width: 12%;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                > :nth-child(4) {
                    display: block;
                    width: 10%;
                    line-height: 80px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                > :nth-child(5) {
                    display: block;
                    width: 12%;
                    line-height: 80px;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                > :nth-child(6) {
                    width: 10%;

                    span {
                        margin: 10px;
                    }

                    :nth-child(1) {
                        margin-top: 1px;
                    }

                    :nth-child(2) {
                        cursor: pointer;
                        &:hover{
                            color: #d82229;
                        }
                    }
                }

                > :nth-child(7) {
                    width: 10%;

                    :last-child {
                        cursor: pointer;
                    }
                }

                img {
                    width: 80px;
                    height: 80px;
                }

                .desc {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    p {
                        &:first-child {
                            color: #333;
                            font-size: 15px;
                            line-height: 20px;
                        }

                        color: #999;
                        font-size: 12px;
                        
                    }
                }

                .price {
                    // margin-right: 94px;
                    color: #ff0036;
                }

                .payStatus {
                    button {
                        padding: 6px;
                        margin-bottom: 5px;
                        color: #fff;
                        background: #d82229;
                        text-align: center;
                        outline: none;
                        border: 0;
                        border-radius: 5px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
</style>

