<template>
  <div class="myOrderInfo start">
    <div class="editBox">
      <div class="avatrual">
        <img src="../../assets/icon/defautSearchImg.jpg" alt="">
      </div>
      <p class="mobile">您好,{{user.mobile | phoneFmt}}</p>
      <!-- <p class="f14">编辑头像</p> -->
    </div>
    <div style="padding-top:20px;padding-bottom:20px" class="row">
      <div class="leftorderInfo" >
        <div class="row">
         
          <!-- <div>
            <p class="myTitle f16 f17">我的比价</p>
            <div class="imgBox flex align-center">
              <img class="priceIcon" src="../../assets/shopImg/personOrderImg/bijia.png" alt>
              <label>比价中 ({{obj.bijia}})</label>
            </div>
          </div> -->
        </div>
        <div class="row mt10">
          <div class="mr45">
            <p class="myTitle f16">我的需求</p>
            <div class="imgBox flex align-center">
              <img class="checkIcon" src="../../assets/shopImg/personOrderImg/daishenhe.png" alt>
              <label class>待审核 ({{obj.xuqiu1}})</label>
            </div>
          </div>
          <div>
            <p class="myTitle bijia">我的比价</p>
            <div class="imgBox flex align-center">
              <img class="zhaoIcon" src="../../assets/shopImg/personOrderImg/zhaobiaozhong.png" alt>
              <label>招标中 ({{obj.xuqiu2}})</label>
            </div>
          </div>
        </div>
         <div class="mr80">
            <p class="myTitle f16">我的积分</p>
            <div class="imgBox flex align-center">
              <img src="../../assets/shopImg/personOrderImg/jifen.png" alt>
              <label class="c0">{{obj.jifen}}</label>
            </div>
          </div>
      </div>
      <div class="rightorderInfo flex flex-center">
        <div class="centerInfo flex-y align-center">
          <img class="icon1" src="../../assets/shopImg/personOrderImg/daizhifu.png" alt>
          <p style="padding-top:30px;">待支付({{obj.daifukuan}})</p>
        </div>
        <div class="centerInfo flex-y align-center">
          <img class="icon2" src="../../assets/shopImg/personOrderImg/zhizuozhong.png" alt>
          <p>制作中({{obj.zhizuozhong}})</p>
        </div>
        <div class="centerInfo flex-y align-center">
          <img class="icon3" src="../../assets/shopImg/personOrderImg/daishouhuo.png" alt>
          <p>待收货({{obj.daishouhuo}})</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      obj:{}
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  created(){
    this.getHtmlData();
  },
  methods: {
    async getHtmlData(){
           await this.$http.get('/tOrder/findGroupByState')
                .then(res => {
                    this.obj = res.data.data;
                    console.log(this.obj)
                });
        },
  },
}
</script>
<style lang="scss" scoped>
@import '../../assets/scss/change.scss';
.myOrderInfo {
  width: 100%;
  background-color: #fff;
  .editBox {
    width: 223px;
    // height: 170px;
    padding: 15px 45px 5px;
    background-color: #d82229;
    color: #fff;
    text-align: center;
    .avatrual {
      width: 93px;
      height: 93px;
      margin: 0 auto;
      background-color: #ccc;
      border-radius: 50%;
      overflow: hidden;
      img{
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    .mobile {
      font-size: 14px;
      padding: 11px 0 15px;
    }
  }
  .leftorderInfo {
    // padding: 23px 50px 18px 38px;
    padding: 0px 45px 0px 58px;
    border-right: 1px solid #ddd;
    .myTitle {
      padding-bottom: 15px;
      color: #333;
    }
    .bijia{
      visibility: hidden;
    }
    .imgBox {
      margin-bottom: 15px;
      img {
        display: block;
        width: 19px;
        height: 19px;
        margin-right: 10px;
      }
      .priceIcon {
        width: 20px;
        height: 20px;
      }
      .checkIcon {
        width: 17px;
        height: 19px;
      }
      .zhaoIcon {
        width: 19px;
        height: 20px;
      }
    }
  }
  .rightorderInfo {
    padding-left: 25px;
    padding-right: 35px;
    text-align: center;
    .centerInfo {
      width: 80px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding:35px 0;
      &:nth-child(2) {
        margin: 0 15px;
      }
      p {
        padding-top: 32px;
        color: #333;
        font-size: 14px;
      }
      .icon1 {
        width: 40px;
        height: 36px;
      }
      .icon2 {
        width: 41px;
        height: 35px;
      }
      .icon3 {
        width: 43px;
        height: 35px;
      }
    }
  }
}

.c0 {
  color: #d82229;
}

.mr45 {
  margin-right: 45px;
}
.mr80 {
  margin-right: 80px;
}

.mt10 {
  margin-top: 10px;
}
</style>


